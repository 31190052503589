import { Component, OnInit } from '@angular/core';
import { ConfigService} from "app/services/config.service";

@Component({
    selector: 'app-not-found',
    standalone: true,
    imports: [],
    templateUrl: './not-found.component.html',
    styleUrl: './not-found.component.scss'
})
export class NotFoundComponent implements OnInit {
    companyLogo: string = "assets/icons/logo.svg"
    errorImage: string = "assets/images/400Error.png"
    statusCode: string = "404"
    errorDescription: string[] = ["The page you requested has been deleted.",
        "Go back to homepage."]
    homepageButton: string = "Back to Homepage"

    constructor(private config: ConfigService) { }

    ngOnInit(): void {

    }

    onBackHomepageClick() {
        window.open('https://admin-panel.exafysolutions.ae/', '_self');
    }
}
